.bttn-viewall {
  @include underline-text-on-hover();
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  font-size: rem(15px);
  font-weight: $font__weight_bold;
  color: inherit;
  padding-top: 10px;
  padding-bottom: 4px;
  text-transform: uppercase;
  margin-bottom: $text__margin_bottom_normal;

  &::after {
    @extend %icon-chevron-right-default;
    content: '';
    display: inline-block;
    width: 1em;
    height: 1em;
    font-size: _em(15px, 15px);
    line-height: _em(17px, 15px);
    overflow: visible;
    background-size: 1.5em;
    background-position: left center;
    background-repeat: no-repeat;
    margin-right: 0;
  }



  &.bright-blue {
    color: $color__dark_blue;
    &::after {@extend %icon-chevron-right-bright-blue;}
  }
  &.bluey-grey {
    color: $color__blue_grey;
    &::after {@extend %icon-chevron-right-bluey-grey;}
  }
  &.mid-blue {
    color: $color__dark_blue;
    &::after {@extend %icon-chevron-right-mid-blue;}
  }
  &.dark-blue {
    color: $color__dark_blue;
    &::after {@extend %icon-chevron-right-dark-blue;}
  }
  &.white {
    color: $color__pure_white;
    &::after {@extend %icon-chevron-right-white;}
  }

}
