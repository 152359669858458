.banner-services {
  $self: &;
  $self__max_width: 1132px;
  width: 100%;
  background-color: rgba($color__pure_white, 1);
  padding: $gutter__mobile;
  // @include debug-outline;
  text-align: center;

  &-inner {
    max-width: $self__max_width;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: _vw(89px, $site__canvas_width) 0;
  }


  &-headline {
    max-width: 960px;
    font-size: rem(26px);
    font-weight: $font__weight_regular;
    color: $color__off_black;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.31;
    letter-spacing: 7px;
    text-align: center;
  }

  // ==========================================================================
  // MEDIA QUERIES
  // ==========================================================================
  @include mq(desktop_sm) {
    // padding: _vw(140px, $site__canvas_width) $gutter__desktop;

    &-inner {

    }
    &-header {
      width: 100% - percentage(615px / $self__max_width);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-content {
      width: percentage(615px / $self__max_width);
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
    }
  }
}
