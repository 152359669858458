// styles for the ul containing all
// of the menu navigation links.
// @example HTML
// <ul class="drawer-menu">
.drawer-menu {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-left: 0;
  padding-right: 0;

  list-style: none;

  &-branding {
    height: 64px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 24px 20px 24px 24px;
  }


  ul {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  ul.active {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* Avoid the IE 10-11 `min-height` bug. */
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; // mobile safari

    >li:last-child {
      $item_height: 46px;
      padding-bottom: $item_height;

      // iPhone 6, 7, & 8 in portrait & landscape
      // iPhone 6, 7, & 8 Plus in portrait & landscape
      // Phone 5 & 5S in portrait & landscape
      @supports (-webkit-overflow-scrolling: touch) {
        padding-bottom: ($item_height * 3);
      }

      // iPhone X in portrait & landscape
      @media only screen and (min-device-width : 375px) and (max-device-width : 812px) and (-webkit-device-pixel-ratio : 3) {
        padding-bottom: ($item_height * 4);
      }

    }
  }

  // List item containing an anchor link
  // @example HTML
  // <li class="drawer-menu__item">
  //   <a href="#">
  //     <span>Abre</span>
  //   </a>
  // </li>
  &-item {
    margin-top: 8px;
    margin-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;

    &-link {
      padding-top: 5px;
      padding-bottom: 5px;
      width: 100%;
      display: inline-block;
      font-size: rem(18px);
      font-weight: $font__weight_regular;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: -0.2px;
      color: $color__pure_white;
      text-decoration: none;
      text-transform: uppercase;
      &:hover {
        text-decoration: none;
      }
    }

    &-actions {
      padding-right: 28px;
      padding-top: 34px;
    }

    &-bttn-join {
      @include bttn($theme: white-white, $size: sm);
    }

    &-bttn-sign-in {
      @include bttn-stroke($theme: white, $size: sm);
    }


    &-bttn-join,
    &-bttn-sign-in {
      width: 100%;
      margin-bottom: 13px;
      border-radius: 7px;
      letter-spacing: -0.2px;
    }

  }



}
