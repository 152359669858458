// ==========================================================================
// Typography Mixins
// ==========================================================================
// TODO: Cleanup typography variables to match natural language rather than h1-h6.
@mixin typo-hero-headline($color: null) {
  color: $color;
  font-family: $font__hero_headline_family;
  font-weight: $font__hero_headline_weight;
  font-size: $font__hero_headline_size_min;
  font-style: $font__hero_headline_style;
  letter-spacing: $font__hero_headline_letter_spacing;
  line-height: $font__hero_headline_line_height;
  font-stretch: normal;
  // @include mq(desktop_sm) {
  //   font-size: $font__headline_size;
  // }
}


@mixin typo-headline($color: null) {
  color: $color;
  font-family: $font__headline_family;
  font-weight: $font__headline_weight;
  font-size: $font__headline_size_min;
  font-style: $font__headline_style;
  letter-spacing: $font__headline_letter_spacing;
  line-height: $font__headline_line_height;
  // @include mq(desktop_sm) {
  //   font-size: $font__headline_size;
  // }
}

@mixin typo-subhead($color: null) {
  color: $color;
  font-family: $font__subhead_family;
  font-weight: $font__subhead_weight;
  font-size: $font__subhead_size_min;
  font-style: $font__subhead_style;
  letter-spacing: $font__subhead_letter_spacing;
  line-height: $font__subhead_line_height;
  // @include mq(desktop_sm) {
  //   font-size: $font__h2_size;
  // }
}

@mixin typo-title($color: null) {
  color: $color;
  font-family: $font__title_family;
  font-weight: $font__title_weight;
  font-size: $font__title_size_min;
  font-style: $font__title_style;
  letter-spacing: $font__title_letter_spacing;
  line-height: $font__title_line_height;
  // @include mq(desktop_sm) {
  //   font-size: $font__title_size;
  // }
}

@mixin typo-button($color: null) {
  color: $color;
  font-size: rem(15px);
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-transform: uppercase;
}

@mixin typo-label($color: $color__body_text) {
  color: $color;
  font-family: $font__h4_family;
  font-weight: $font__h4_weight;
  font-size: $font__h4-size_min;
  font-style: normal;
  line-height: $font__h4_line_height;
  // @include mq(desktop_sm) {
  //   font-size: $font__h4_size;
  // }
}



@mixin typo-menu($color: null) {
  color: $color;
  font-family: $sans;
  font-weight: $font__weight_bold;
  font-size: rem(17px);
  font-style: normal;
  line-height: 1.25;
  // @include mq(desktop_sm) {
  //   font-size: rem(17px);
  // }
}

@mixin typo-body-sm($color: null) {
  color: $color;
  font-family: $font__body_family;
  font-weight: $font__body_weight;
  font-size: $font__body_size_min;
  font-style: $font__body_style;
  letter-spacing: $font__body_letter_spacing;
  line-height: $font__body_line_height;
  // @include mq(desktop_sm) {
  //   font-size: rem(15px);
  // }
}


@mixin typo-body($color: null) {
  color: $color;
  font-family: $font__body_family;
  font-weight: $font__body_weight;
  font-size: $font__body_size_min;
  font-style: $font__body_style;
  letter-spacing: $font__body_letter_spacing;
  line-height: $font__body_line_height;
  // @include mq(desktop_sm) {
  //   font-size: rem(15px);
  // }
}

@mixin typo-body-lrg($color: null) {
  font-family: $font__body_lrg_family;
  font-weight: $font__body_lrg_weight;
  font-size: $font__body_lrg_size_min;
  font-style: $font__body_lrg_style;
  letter-spacing: $font__body_lrg_letter_spacing;
  line-height: $font__body_lrg_line_height;
  // @include mq(desktop_sm) {
  //   font-size: rem(18px);
  // }
}

@mixin typo-micro($color: null) {
  color: $color;
  font-family: $sans;
  font-weight: $font__weight_regular;
  font-size: rem(13px);
  font-style: normal;
  line-height: 1.4;
  // @include mq(desktop_sm) {
  //   font-size: rem(13px);
  // }
}

@mixin typo-legal($color: null) {
  color: $color;
  font-family: $sans;
  font-weight: $font__weight_regular;
  font-size: rem(11px);
  font-style: normal;
  line-height: 1.4;
  // @include mq(desktop_sm) {
  //   font-size: rem(11px);
  // }
}






// @mixin typo-body-copy-link-styles($color: null, $hover_color: null) {

//   a {
//     color: $color;
//     text-decoration: none;
//     font-family: inherit;
//     font-weight: inherit;
//     font-size: inherit;
//     @include hover {
//       color: $hover_color;
//       text-decoration: underline;
//     }
//   }
// }
