.superhero {
  $self: &;
  &.superhero-home {
    position: relative;
    background-color: $color__off_black;

    .superhero-content {
      overflow: hidden;
      max-width: none;
      padding: 0 0 0 0;
      padding-top: _vw(211px, $site__canvas_width);
      padding-bottom: _vw(353px, $site__canvas_width);


      &::after {
        z-index: -10;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: block;
        background: rgba($color__pure_black, 0.2);
      }

      // Alternative BG image fallback to avoid
      // flash of video poster via poster attribute
      &::before {
        @include bg_image('summit-video-bg-poster.jpg');
        z-index: -101;
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        animation: fade-in-video 3s ease-in-out;
      }
      @keyframes fade-in-video {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
    }

    .super-hero-video-bg {
      position: absolute;
      top: 50%;
      left: 50%;
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      z-index: -100;
      transform: translateX(-50%) translateY(-50%);
      object-fit: cover;
      overflow: hidden;
      animation: fade-in-video 1.5s $animation__curve_default;
      @keyframes fade-in-video {
        0% { opacity: 0; }
        100% { opacity: 1; }
      }
    }


    .superhero-header {
      font-family: $sans;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 18px;
      padding-right: 18px;
      max-width: 1089px + 18px + 18px;
      position: relative;

    }


    @include mq(tablet_md) {
      .rwd-br {
        display: inline;
      }
    }
  }

}
