input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=number],
input[type=tel],
input[type=range],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=color],
textarea {
  font-family: inherit;
  border-style: solid;
  border-width: thin;
}

input:-webkit-autofill {
  background-color: inherit !important;
  color: inherit !important;
}

input[type=text],
input[type=email],
input[type=url],
input[type=search] {
  text-transform: none;
}

input[type=text],
input[type=email],
input[type=url],
input[type=search],
input[type=password] {
  &::placeholder {
    text-transform: none;
  }
}

// textarea {}
button,
input[type=button],
input[type=reset],
input[type=submit] {
  // border: none;
  // border-radius: 0;
  // box-shadow: none;
  // width: auto;
  // padding: $button__padding;
  // font: normal none $button__font-weight #{$button__font-size}/#{$button__line_height} $button__font-family;
  // background-color: $button__background-color;
  // color: $button__color-text;
}

textarea {
  width: 100%;
}

input,
textarea,
select {
  user-select: text;
}

button,
input,
textarea {
  appearance: none;
}

input[type=image] {

}

fieldset {
  border: 1px solid $color__border;

  ul {
    list-style: none;
  }
}

legend {
  border: 0;
  padding: 0;
}

button,
input[type=submit] {
  cursor: pointer;
}



/*================ Input element overrides ================*/

input[type=checkbox],
input[type=radio] {
  display: inline;
  margin: 0 8px 0 0;
  padding: 0;
  width: auto;
}

input[type=checkbox] {
  appearance: checkbox;
}

input[type=radio] {
  appearance: radio;
}

