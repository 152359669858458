/*============================================================================
  # Utility Classes
==============================================================================*/
/* stylelint-disable declaration-no-important */
.is-transitioning {
  display: block !important;
  visibility: visible !important;
}
/* stylelint-enable declaration-no-important */

.menu-text {
  @include typo-menu();
}

.hero-headline {
  @include typo-hero-headline();
}

.headline {
  @include typo-headline();
}
.subhead {
  @include typo-subhead();
}

.title-text {
  @include typo-title();
}

.body-text {
  @include typo-body();
}

.body-text-lrg {
  @include typo-body-lrg();
}

.label-text {
  @include typo-label();
}

.micro-text {
  @include typo-micro();
}

.legal-text {
  @include typo-legal();
}

.pad-top {
  &-xs {
    padding-top: 5px;
  }
  &-sm {
    padding-top: 10px;
  }
  &-md {
    padding-top: 15px;
  }
  &-lg, &-gutter-mobile {
    padding-top: $gutter__mobile;
  }
  &-xl, &-gutter-desktop {
    padding-top: $gutter__desktop;
  }
}

.collapse-content {
  display: none;
  &.expanded {
    display: block;
  }
}
.collapse-content-expand-bttn {
    appearance: none;
    background-color: transparent;
    border: none;
    color: $color__dark_blue;
    font-size: rem(15px);
    font-weight: $font__weight_bold;
    line-height: 1.8;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding: 14px 6px;

    &::after {
      @extend %icon-chevron-right-bright-blue;
      content: '';
      display: inline-block;
      width: _em(12px, 18px);
      height: _em(18px, 18px);
      font-size: _em(18px, 18px);
      line-height: _em(17px, 13px);
      overflow: visible;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      margin: 6px;
      transform: rotate(90deg) translateX(1px);
    }

    &.active {
      &::after {
        transform: rotate(-90deg) translateX(2px);
      }
    }
    &:focus {
      outline: none;
    }
  }
