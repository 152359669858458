.site-nav {
  $self: &;
  // display: none; // goes to flex on desktop
  margin: 0 auto;
  justify-content: space-between;
  background-color: transparent;
  width: 100%;
  height: 100%;
  max-width: 1089px + ($gutter__mobile * 2);

  &-branding {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    // width: 100%;
    width: 25%;
    max-width: 178px;
    &-link {
      width: 100%;
      height: 100%;
      display: block;
    }
    &-link-logo {
      width: 100%;
      height: 100%;
      display: block;

      &.logo-white {
        display: inline-block;
      }

      &.logo-color {
        display: none;
      }
    }
  }

  &-menu-area {
    width: 100%;
    max-width: 772px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    // @include debug-outline;
  }
  &-toolbar-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  &-toolbar-button {
    width: 143px;
    height: 34px;
    display: inline-flex;
    padding: 0;
    line-height: 1;
    text-align: center;
    text-decoration: none;
    border: none;
    background-color: transparent;
    .simmer-button-svg {
      .box {
        fill: transparent;
        stroke: $color__pure_white;
      }
      .text {
        fill: $color__pure_white;
        stroke: none;
      }
    }
    &:hover {
      .simmer-button-svg {
        .box {
          fill: $color__pure_white;
          stroke: $color__pure_white;
        }
        .text {
          fill: $color__dark_blue;
          stroke: none;
        }
      }
    }
  }

  &-menu {
    display: flex;
    width: 100%;
    max-width: 772px;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
    align-self: flex-end;
  }

  &-menu-list {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &-menu-item {
    text-align: center;
    display: inline-block;
    padding-left: 17px;
    padding-right: 17px;

    &:last-child {
      padding-right: 0;
    }
  }

  &-menu-item-link {
    display: block;
    text-decoration: none;
    border: none;
    position: relative;
    font-size: rem(15px);
    font-weight: $font__weight_semibold;
    letter-spacing: 2.18px;
    text-transform: uppercase;
    color: rgba($color__pure_white, 1);
    transition-property: color, border-color;
    transition-duration: 0.8s, 0.3s;
    transition-timing-function: ease-in-out;
    line-height: 2;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 100%;
      right: 1px; // fixes space at end of text
      bottom: 0;
      left: 0;
      margin: 0 auto;
      height: 3px;
      width: 20%;
      color: rgba($color__pale_grey, 1);
      background-color: rgba($color__pale_grey, 0);
      transition: width 0.3s $transition__default_function, background-color 0.3s ease-in-out;
    }
    &:hover,
    &:focus {
      outline-offset: -1px;
    }

    &.active,
    &:active {
      text-decoration: none;
      outline: none;
    }

    &:hover,
    &.active,
    &:active {
      &::after {
        color: rgba($color__pale_grey, 1);
        background-color: rgba($color__pale_grey, 1);
        width: calc(100% - 2px);
        // width: calc(100% - 35px);
      }
    }
  }


  &__site-branding {
    height: 100%;
    width: 100%;
    padding-left: 24.5px;
    position: absolute;
    flex-direction: row;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    // TODO: prevent logo link from streching 100%
    .site-branding-logo {
      height: 100%;
      width: auto;
      display: flex;
      align-items: center;
      cursor: pointer;

      &:focus,
      &:active {
        outline: $focus__outline_style;
      }
    }

    .site-logo {
      width: 169px;
      height: 45px;
      background: transparent;
    }

    .site-title,
    .site-title {
      margin: 0;
      font-size: rem(17px);

      a {
        color: $color__dark_blue;
      }
    }

    .site-description {
      margin: 0;
      font-size: 12px;
      font-weight: normal;
    }

    .site-title,
    .site-description,
    .site-title {
      line-height: 1;
    }
  }

  @include mq(tablet_md) {
    display: flex;
  }

  @include mq(desktop_md) {

    // Temporary fix for centering menu (sm and lg)
    // &__menu {
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: center;
    // }
    // &__main-menu {

    //   &-item > a {
    //     padding-left: $site_nav__item_padding_xlarge;
    //     padding-right: $site_nav__item_padding_xlarge;
    //   }
    // }
  }
}
