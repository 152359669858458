// Mixin defining a ghost button
// Notes:
// - All arguments default to variables set in buttons-settings.
//
// - Theme keys must be stored in the `$map-bttn-ghost-themes` in
//   `_buttons-themes.scss`
//
// - If passing individual arguments you must pass null as the first argument
// - and use name arguments unless all arguments are passed [see underloaded example].
//
// Examples:
// default:         bttn-ghost()
// theme:           bttn-ghost(red)
// overloaded:      bttn-ghost(null, #000, #000)
// oveunderloaded:  bttn-ghost(null, $icon-fill: #000)

@mixin bttn-ghost(
        $theme:      null,
        $size:       null,
        $font-size:  null,
        $block:      null
     ) {
        // Initialize the $map variable so it can be set dynamically
        $map: null;
        // Check for theme and set args to those values
        @if $theme {
            $map: map-get($map-bttn-ghost-themes, $theme);
        } @else {
            $map: $bttn-ghost-theme-defaults;
        }

        $font-color: map-get($map, font-color);
        $icon-fill: map-get($map, icon-fill);

        // include shared styles of non ghost button
        @include bttn-base();

        // include button transition properties
        @include bttn-hover-transition();

        // xl or sm is passed through when this is called from an alias
        @include bttn-size($size, $font-size);

        @if $block {
            display: block;
            width: 100%;
        }
        @else {
            display: inline-block;
        }

        border: none;
        background: transparent;
        color: $font-color;

        .icon {
            fill: $icon-fill;
        }

        &:focus,
        &:active,
        &:hover {
            color: $font-color;
        }
}
