////////////////////////////////////////////////////////////
// 4. Stroke Button Themes ////////////////////////////////
//////////////////////////////////////////////////////////
$map_bttn_stroke_themes: (
  default:
  (
    font-color: $color__pure_white,
    font-color-active: $color__pure_white,
    bg-color: transparent,
    bg-color-active: transparent,
    border-color: $color__pure_white,
    border-color-active: $color__pure_white,
    icon-fill: $color__pure_white,
    icon-fill-active: $color__pure_white
  ),
  white:
  (
    font-color: $color__pure_white,
    font-color-active: $color__pure_white,
    bg-color: transparent,
    bg-color-active: transparent,
    border-color: $color__pure_white,
    border-color-active: $color__pure_white,
    icon-fill: $color__pure_white,
    icon-fill-active: $color__pure_white
  ),
  dark-blue:
  (
    font-color: $color__dark_blue,
    font-color-active: $color__dark_blue,
    bg-color: transparent,
    bg-color-active: transparent,
    border-color: $color__dark_blue,
    border-color-active: $color__dark_blue,
    icon-fill: null,
    icon-fill-active: null
  ),
  mid-blue:
  (
    font-color: $color__dark_blue,
    font-color-active: $color__dark_blue,
    bg-color: transparent,
    bg-color-active: transparent,
    border-color: $color__dark_blue,
    border-color-active: $color__dark_blue,
    icon-fill: null,
    icon-fill-active: null
  ),
  bluey-grey:
  (
    font-color: $color__blue_grey,
    font-color-active: $color__blue_grey,
    bg-color: transparent,
    bg-color-active: transparent,
    border-color: $color__blue_grey,
    border-color-active: $color__blue_grey,
    icon-fill: null,
    icon-fill-active: null
  )
);
