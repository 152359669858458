.banner-cta {
  $self: &;
  $self_canvas_width: $site__canvas_width;
  @include background_image_retina('banner-cilantro.jpg', 'banner-cilantro@2x.jpg');
  display: flex;
  width: 100%;
  min-height: _vw(477px, $self_canvas_width);  //image height,width
  position: relative;
  padding: _vw(117px, $self_canvas_width) $gutter__mobile;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;

  &-partners {
    @include background_image_retina('banner-parsley.jpg', 'banner-parsley@2x.jpg');
  }

  &-inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &-content {
    text-align: center;


    &-headline {
      // max-width: 902px;
      font-size: rem(28px);
      font-weight: $font__weight_regular;
      color: $color__pure_white;
      text-transform: uppercase;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 7px;
      margin-bottom: rem(15px);

      &.to-lowercase {
        font-size: rem(28px);
        text-transform: none;
        line-height: 1.58;
        letter-spacing: normal;
      }

      + .banner-cta-button {
        margin-top: rem(32px);
      }
    }

    p {
      max-width: 852px;
      font-size: rem(24px);
      font-weight: $font__weight_regular;
      color: $color__pure_white;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.58;
      margin-bottom: rem(32px);
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-button {
    display: inline-flex;
    padding: 14px 32px;
    font-size: rem(15px);
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.75px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: $color__dark_blue;
    border: 1px solid $color__pure_white;
    background-color: $color__pure_white;
    &:hover {
      color: $color__pure_white;
      background-color: $color__dark_blue;
      border-color: $color__dark_blue;
    }
  }

  @include mq(desktop_md) {
    max-height: 477px;
    min-height: 1px;
    padding-left: $gutter__desktop;
    padding-right: $gutter__desktop;
  }

}
