.bio {
  $self: &;
  $self_max_width: 1129px;
  width: 100%;
  background-color: rgba($color__pure_white, 1);
  text-align: center;
  padding: $gutter__mobile;

  &-inner {
    margin: auto;
    background-color: $color__off_white;
    padding: _vw(114px, $site__canvas_width) $gutter__mobile;
  }

  &-content {
    max-width: $self_max_width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  &-content-photo {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &-content-details {
    text-align: left;

    p {
      font-size: rem(18px);
      font-weight: $font__weight_regular;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.78;
      letter-spacing: normal;
      color: $color__off_black;
      margin-bottom: rem(24px);
    }
  }

  &-headline {
    font-size: rem(26px);
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: 6px;
    color: $color__off_black;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: $gutter__mobile;
  }

  &-byline {
    font-size: rem(18px);
    font-weight: $font__weight_semibold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $color__off_black;
    display: block;
    margin-bottom: rem(36px);
  }

  &-cta {
    p {
      font-weight: $font__weight_semibold;
      margin-bottom: rem(10px);
    }

    .simmer-button {
      width: 199px;
      height: 47px;
      display: inline-block;
      padding: 0;
      line-height: 1;
      text-align: center;
      text-decoration: none;
      border: none;
      background-color: transparent;
      position: relative;

      .simmer-button-svg {
        width: 100%;
        height: 100%;
        .box {
          fill: transparent;
          stroke: $color__dark_blue;
        }
        .text {
          fill: $color__dark_blue;
          stroke: none;
        }
      }
      &:hover {
        .simmer-button-svg {
          .box {
            fill: $color__dark_blue;
            stroke: $color__dark_blue;
          }
          .text {
            fill: $color__pure_white;
            stroke: none;
          }
        }
      }
    }

  }

  @include mq(tablet_sm) {
    &-content {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }
    &-content-photo {
      width: 25%;
    }
    &-content-details {
      width: 75%;
      padding-left: percentage(89px / $self_max_width);
    }
  }


  @include mq(desktop_md) {

    &-content-photo {
      width: percentage(482px / $self_max_width);
    }
    &-content-details {
      width: percentage((558px + 89px) / $self_max_width);
      padding-left: percentage(89px / $self_max_width);
    }

  }
}
