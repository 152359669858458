.intro {
  $self: &;
  $self__max_width: 1132px;
  width: 100%;
  background-color: rgba($color__pure_white, 1);
  padding: _vw(140px, $site__canvas_width) $gutter__mobile;
  // @include debug-outline;

  &-inner {
    max-width: $self__max_width;
    margin: auto;
    display: flex;
    flex-direction: column;
  }

  &-header {
    padding-right: _vw(44px, $site__canvas_width);
  }

  &-headline {
    font-size: rem(28px);
    font-weight: $font__weight_regular;
    color: $color__off_black;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.14;
    letter-spacing: 7px;

  }

  &-content {

    p {
      font-size: rem(18px);
      color: $color__off_black;
      font-weight: $font__weight_regular;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      margin-bottom: rem(24px);
    }
  }


  // ==========================================================================
  // MEDIA QUERIES
  // ==========================================================================
  @include mq(desktop_sm) {
    padding: _vw(140px, $site__canvas_width) $gutter__desktop;

    &-inner {
      flex-direction: row;
    }
    &-header {
      width: 100% - percentage(615px / $self__max_width);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-content {
      width: percentage(615px / $self__max_width);
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
    }
  }
}
