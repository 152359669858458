.link {
  position: relative;
  font-size: rem(15px);
  color: $color__dark_blue;
  font-weight: $font__weight_semibold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.81px;
  text-transform: uppercase;
  text-decoration: none;
}

.link-more {
  position: relative;
  font-size: rem(15px);
  color: $color__dark_blue;
  font-weight: $font__weight_semibold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 2.81px;
  text-transform: uppercase;
  text-decoration: none;

  &::after {
    content: '';
    display: block;
    position: absolute;
    // top: 100%;
    right: 2px; // fixes space at end of text
    bottom: -7px;
    left: -2px;
    margin: 0 auto;
    height: 3px;
    width: 20%;
    color: rgba($color__dark_blue, 1);
    background-color: rgba($color__dark_blue, 0);
    transition: width 0.3s $transition__default_function, background-color 0.3s ease-in-out;
  }
  &:hover,
  &.active,
  &:active {
    &::after {
      color: rgba($color__dark_blue, 1);
      background-color: rgba($color__dark_blue, 1);
      width: calc(100% - 2px);
      // width: calc(100% - 35px);
    }
  }
}
