// ==========================================================================
// Columns list (.columns-list)
// A module for converting a <ul> into a multi column layout
// example use: resources > models > index
// ==========================================================================
.promise {
  $self: &;
  $self_max_width: 1572px;
  $self_content_max_width: 563px;
  width: 100%;
  background-color: rgba($color__pure_white, 1);
  text-align: center;
  padding: $gutter__mobile;
  position: relative;

  &-inner {
    // @include background_image_retina('summit-promise.jpg', 'summit-promise@2x.jpg');
    width: 100%;
    margin: auto;
    position: relative;
    background-color: $color__pure_black;
  }

  &-img {
    width: 100%;
    height: auto;
    display: block;
  }

  &-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    padding-bottom: 80px;
    padding-left: $gutter__mobile;
    padding-right: $gutter__mobile;
  }

  &-header {
    margin-top: $gutter__mobile;
    margin-bottom: $gutter__mobile;
  }

  &-headline {
    text-transform: uppercase;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    color: $color__pure_white;
    text-align: left;

    .line {
      display: block;
    }

    .line-1 {
      font-size: rem((24px * 0.5));
      line-height: 1;
      letter-spacing: (6px * 0.5);
      margin-bottom: 6px;
    }

    .line-2 {
      font-size: rem((32px * 0.45));
      line-height: 1;
      letter-spacing: (8px * 0.5);
      margin-bottom: 4px;
    }

    .line-3 {
      font-size: rem((46px * 0.5));
      line-height: 1;
      letter-spacing: (11.5px * 0.5);
    }

    &::after {
      content: '';
      display: block;
      width: 60px;
      height: 4px;
      opacity: 0.3;
      background-color: #ffffff;
      margin-top: rem(44px * 0.5);
    }
  }

  &-body {
    text-align: left;
    p {
      font-size: rem(16px);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      color: $color__pure_white;
    }
  }

  &-footer {
    text-align: left;
    h3 {
      font-size: rem(24px * 0.6);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: 5.54px;
      color: $color__pure_white;
      text-transform: uppercase;
      margin-bottom: 16px;
    }

    a {
      display: inline-block;
      padding: 10px 20px;
      font-size: rem(13px);
      font-weight: $font__weight_semibold;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 3.25px;
      text-align: center;
      text-decoration: none;
      color: $color__dark_blue;
      border: 1px solid $color__pure_white;
      background-color: $color__pure_white;
      text-transform: uppercase;

      &:hover {
      }
    }
  }

  @include mq(tablet_md) {
  }

  @include mq(tablet_md) {
    &-inner {
      height: 0;
      padding-bottom: aspect-ratio-fixed(1572, 1050);
    }

    &-img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      object-fit: cover;
    }

    &-content {
      justify-content: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      padding-left: _vw(70px, 1574px);
    }

    &-content-inner {
      display: flex;
      flex-direction: column;
      height: percentage(858px / 1050px);
      justify-content: space-around;
    }

    &-body {
      max-width: 58%;

      p {
        font-size: rem(15px);
      }
    }

    &-footer {
      text-align: left;
      h3 {
        line-height: 3.33;
      }
    }
  }

  @include mq(tablet_lg) {
    &-body {
      max-width: 44%;
    }
  }

  @include mq(desktop_sm) {
    &-headline {
      margin-bottom: 0;

      .line-1 {
        font-size: rem(24px);
        line-height: 1.17;
        letter-spacing: 6px;
      }

      .line-2 {
        font-size: rem(32px);
        line-height: 1.63;
        letter-spacing: 8px;
      }

      .line-3 {
        font-size: rem(46px);
        line-height: 1.17;
        letter-spacing: 11.5px;
      }
    }
  }

  @include mq(desktop_md) {
    &-content {
      padding-left: _vw(132px, 1574px);
    }

    &-body {
      margin-top: 0;
      max-width: 555px;
      p {
        font-size: rem(22px);
      }
    }

    &-footer {
      h3 {
        font-size: rem(24px);
        line-height: 1.5;
      }
      a {
        padding: 15px 30px;
        font-size: rem(13px);
      }
    }
  }
}
