// ==========================================================================
// Extends for icon-before-after
//
// Extends are used to minimize the redundancy of svg data URIs in
// the stylesheet to keep the file size from bloating and as a preference
// to leverage the stylesheets's browser cacheability over inline svg
// fragment references which can not be cached by the browser.
// ==========================================================================

%icon-chevron-right-default {
  @include svg-icon($id: 'ion-ios-arrow-forward', $fill_color: $color__body_text, $property: 'background-image');
}

%icon-chevron-right-bluey-grey {
  @include svg-icon($id: 'ion-ios-arrow-forward', $fill_color: $color__blue_grey, $property: 'background-image');
}

%icon-chevron-right-white {
  @include svg-icon($id: 'ion-ios-arrow-forward', $fill_color: $color__pure_white, $property: 'background-image');
}

%icon-chevron-right-mid-blue {
  @include svg-icon($id: 'ion-ios-arrow-forward', $fill_color: $color__dark_blue, $property: 'background-image');
}

%icon-chevron-right-bright-blue {
  @include svg-icon($id: 'ion-ios-arrow-forward', $fill_color: $color__dark_blue, $property: 'background-image');
}

%icon-chevron-right-dark-blue {
  @include svg-icon($id: 'ion-ios-arrow-forward', $fill_color: $color__dark_blue, $property: 'background-image');
}

%icon-external-link-bright-blue {
  @include svg-icon($id: 'ionicons-md-open', $fill_color: $color__dark_blue, $property: 'background-image');
}

%icon-document-white {
  @include svg-icon($id: 'ionicons-document', $fill_color: $color__pure_white, $property: 'background-image');
}

%icon-checkmark-white {
  @include svg-icon($id: 'ionicons-checkmark', $fill_color: $color__pure_white, $property: 'background-image');
}

