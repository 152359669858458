@mixin underline-text($color: null, $size: 3px, $gap: null) {
  border-bottom-style: solid;
  text-decoration: none;
  border-bottom-color: $color;
  border-bottom-width: $size;
  padding-bottom: $gap;
}


@mixin underline-text-on-hover($color: null, $size: 3px, $gap: null) {
  $underline_color: null;

  @if $color {
    $underline_color: rgba($color, 0);
  }

  @else {
    $underline_color: transparent;
  }

  $underline_size: $size;
  $underline_gap: $gap;
  @include underline-text($color: $underline_color,
  $size: $underline_size,
  $gap: $underline_gap);

  &:hover {
    @if $color {
      border-bottom-color: rgba($underline_color, 1);
    }

    @else {
      border-bottom-color: inherit;
    }
  }
}

@mixin underline-text-partial($color: null, $size: 3px, $gap: 10px) {
  position: relative;
  padding-bottom: $gap + $size;
  &::after {
    content: '';
    position: absolute;
    bottom: $size;
    left: 0.5em;
    right: 0.5em;
    height: $size;
    display: block;

  }
}

@mixin underline-text-partial-left($color: null, $size: 3px, $gap: 10px) {
  position: relative;
  padding-bottom: $gap + $size;
  &::after {
    content: '';
    position: absolute;
    bottom: $size;
    right: 0.5em;
    height: $size;
    display: block;

  }
}

@mixin underline-text-partial-color($color: $color__dark_blue) {
  &::after {
    background-color: $color;
  }
}
