.superhero {
  $self: &;
  &.superhero-services {
    @include background_image_retina('superhero-services.jpg', 'superhero-services@2x.jpg');
    @include mq(tablet_md) {
      .rwd-br {
        display: inline;
      }
    }
  }
}
