.feed {
  $self: &;
  background-color: $color__off_white;
  padding-top: _vw(100px, $site__canvas_width);
  padding-bottom: _vw(100px, $site__canvas_width);
  padding-left: $gutter__mobile;
  padding-right: $gutter__mobile;

  &-inner {
    width: 100%;
    max-width: 1089px + ($gutter__mobile * 2);
    margin: 0 auto;
  }

  &-header {
    text-align: center;
    margin-bottom: 44px;

    &-headline {
      font-size: rem(28px);
      font-weight: $font__weight_regular;
      font-stretch: normal;
      line-height: 1.07;
      letter-spacing: 6.46px;
      text-align: center;
      text-transform: uppercase;
    }

    p {
      font-size: rem(18px);
      line-height: 1.67;
      text-align: center;
    }
  }

  &-output {
    display: flex;
    flex-direction: column;
    width: 100%;

    .loader,
    .loader:after {
      border-radius: 50%;
      width: 5em;
      height: 5em;
    }
    .loader {
      margin: 0 auto 40px auto;
      font-size: 10px;
      position: relative;
      text-indent: -9999em;
      border-top: 0.5em solid rgba($color__dark_blue, 0.2);
      border-right: 0.5em solid rgba($color__dark_blue, 0.2);
      border-bottom: 0.5em solid rgba($color__dark_blue, 0.2);
      border-left: 0.5em solid $color__dark_blue;
      -webkit-transform: translateZ(0);
      -ms-transform: translateZ(0);
      transform: translateZ(0);
      -webkit-animation: load8 1.1s infinite linear;
      animation: load8 1.1s infinite linear;
    }
    @-webkit-keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
    @keyframes load8 {
      0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
      }
      100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
      }
    }
  }



  &-entry {
    width: 100%;
    margin: 0;
    padding: 10px;
    margin-bottom: $gutter__mobile;
  }

  &-entry-visual {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    margin-bottom: 18px;
    overflow: hidden;
    position: relative;
    background-size: cover;
    background-position: center;
  }

  &-entry-title {
    font-size: rem(15px);
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: 3.75px;
    color: $color__dark_blue;
    a {
      color: inherit;
      font-size: inherit;
      font-weight: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-bio-widget {
    display: flex;
    // flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    max-width: 704px;
    margin: 60px auto 0 auto;

  }

  &-bio-widget-body {
    padding-left: 10px;
    padding-top: 20px;
    font-size: rem(14px);
    line-height: 1.61;
    color: $color__off_black;
    @include mq(mobile_md) {
      padding-top: 0;
      padding-left: 26px;
      font-size: rem(18px);
    }

    a {
      color: $color__dark_blue;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-bio-widget-figure {
    flex: 0 0 80px;
    width: 80px;

  }

  &-bio-widget-figure img {
    display: block;
    width: 100%;
    height: auto;
  }



  @include mq(tablet_md) {
    &-output {
      flex-direction: row;
    }

    &-entry {
      width: percentage(1/3);
      margin: 0;
      padding: 10px;
    }
  }

}
