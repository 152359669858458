.superhero {
  $self: &;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  > * {
    z-index: 2;
  }
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    // background: red;
    background: linear-gradient(180deg, rgba(0,0,0,0.85) 0%, rgba(0,0,0,0) 100%);
    height: percentage(300px / 904px);
    z-index: 1;
  }


  &-content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: _vw(211px, $site__canvas_width);
    padding-bottom: _vw(353px, $site__canvas_width);
    padding-left: 18px;
    padding-right: 18px;
    max-width: 1089px + 18px + 18px;
    position: relative;
  }

  &-header {}


  &-headline {
    @include typo-hero-headline();
    text-transform: uppercase;
    color: $color__pure_white;

    .rwd-br {
      display: none;
    }
  }

  &-actions {
    display: flex;
    flex-direction: column;
    margin-top: 53px;
    justify-content: space-between;
  }

  &-cta-button {
    display: inline-block;
    align-self: center;
    padding: 14px 24px;
    font-size: rem(13px);
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.25px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: $color__pure_white;
    border: 2px solid $color__pure_white;
    background-color: transparent;
    transition-property: color, border-color, background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    &:hover {
      color: $color__dark_blue;
      background-color: $color__pure_white;
      border-color: $color__pure_white;
    }
  }

  &-video-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 14px 24px;
    font-size: rem(13px);
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.25px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    color: $color__pure_white;
    transition-property: color, border-color;
    transition-duration: 0.8s, 0.3s;
    transition-timing-function: ease-in-out;
    background-color: transparent;
    border: none;

    .play-icon {
      .triangle {
        fill: none;
        stroke: #fff;
      }
      .circle {
        fill: none;
        stroke: #fff;
      }
    }


    .button-text {
      display: inline-block;
      padding-left: 10px;
      padding-bottom: 5px;
      position: relative;
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        right: 1px; // fixes space at end of text
        bottom: 0;
        left: 10px;
        margin: 0 auto;
        height: 3px;
        width: 20%;
        color: rgba($color__pure_white, 1);
        background-color: rgba($color__pure_white, 0);
        transition: width 0.3s $transition__default_function, background-color 0.3s ease-in-out;
      }
    }

    &:hover,
    &.active,
    &:active,
    &:focus {

      .play-icon {
        .triangle {
          fill: #000;
          stroke: #000;
        }
        .circle {
          fill: #fff;
          stroke: #fff;
        }
      }

      .button-text::after {
        color: rgba($color__pure_white, 1);
        background-color: rgba($color__pure_white, 1);
        width: calc(100% - 11px);
        // width: calc(100% - 35px);
      }
    }
  }

  @include mq(mobile_lg) {
    &-actions {
      flex-direction: row;
    }
  }

}
