//////////////////////////////////////////////////////////////////////
// BUTTON CLASSES
// Add these to markup where needed but avoid extending them directly
// eaxmple: @extend .some-button
// Instead, use the placeholer extends from the bttn mixin
// library directly and create new extends here/there where needed.
// example @extend %some-button
// If there's a special case, use the mixins directly in modules.
/////////////////////////////////////////////////////////////////////
// TODO: Make raw selectors only use minimum amount of styles
// to fit theme by using settings vars instead of the actual bttn
// mixin to avoid things like buttons for nav etc
// button,
%bttn-base {
  @include bttn-base();
}

input[type=button],
input[type=reset],
input[type=submit],
button {
  @extend %bttn-base;
}

//////////////////////////////////////////////////////////
// 1.0 Primary Buttons (standard) ///////////////////////
////////////////////////////////////////////////////////
%bttn {
  @include bttn();
}

.#{$bttn-namespace} {
  @extend %bttn;
}

%bttn-red-red {
  @include bttn($theme: 'red-red');

}

.bttn-red {
  @extend %bttn-red-red;
}

%bttn-red-white {
  @include bttn($theme: 'red-white');
}

.bttn-red-white {
  @extend %bttn-red-white;
}


%bttn-white-red {
  @include bttn($theme: 'white-red');
}

.bttn-white-red {
  @extend %bttn-white-red;
}

%bttn-mid-blue {
  @include bttn($theme: 'mid-blue');
}

.bttn-mid-blue {
  @extend %bttn-mid-blue;
}

%bttn-dark-blue {
  @include bttn($theme: 'dark-blue');
}

.bttn-dark-blue {
  @extend %bttn-dark-blue;
}

%bttn-bright-blue {
  @include bttn($theme: 'bright-blue');

}

.bttn-bright-blue {
  @extend %bttn-bright-blue;
}


// //////////////////////////////////////////////////////////
// // 1.1 Primary Buttons (small) //////////////////////////
// ////////////////////////////////////////////////////////
%bttn-sm {
  @include bttn($size:'sm');
}

.#{$bttn-namespace}-sm {
  @extend %bttn-sm;
}

%bttn-dark-blue-sm {
  @include bttn($theme: 'dark-blue', $size:'sm');
}

%bttn-mid-blue-sm {
  @include bttn($theme: 'mid-blue', $size:'sm');
}

// //////////////////////////////////////////////////////////
// // 1.2 Primary Buttons (large) /////////////////////////
// ////////////////////////////////////////////////////////
.#{$bttn-namespace}-lg {
  @include bttn($size: lg, $font-size: lg);

}
// WHITE-RED
%bttn-white-red-lg {
  @include bttn($theme: 'white-red', $size: 'lg');
}

.bttn-white-red-lg {
  @extend %bttn-white-red-lg;
}

%bttn-white-red-sm {
  @include bttn($theme: 'white-red', $size: 'sm');

}

.bttn-white-red-sm {
  @extend %bttn-white-red-sm;
}
// RED-WHITE
%bttn-red-white-lg {
  @include bttn($theme: 'red-white', $size: 'lg');
}

.bttn-red-white-lg {
  @extend %bttn-red-white-lg;
}

%bttn-red-white-sm {
  @include bttn($theme: 'red-white', $size: 'sm');
}

.bttn-red-white-sm {
  @extend %bttn-red-white-sm;
}

// //////////////////////////////////////////////////////////
// // 1.2 Primary Buttons (xlarge) /////////////////////////
// ////////////////////////////////////////////////////////
// .#{$bttn-namespace}-xl {
//   @include bttn($size: xl, $font-size: xl);
// }

// //////////////////////////////////////////////////////////
// // Stroke Buttons (small) //////////////////////////
// ////////////////////////////////////////////////////////
%bttn-stroke-sm {
  @include bttn-stroke($size: sm, $font-size: sm);
}

%bttn-stroke-white-md {
  @include bttn-stroke($theme: white, $size: md, $font-size: 18px);
  text-transform: none;
  border-radius: 6.25px;
}

%bttn-stroke-dark-blue-sm {
  @include bttn-stroke($theme: 'dark-blue', $size: 'sm');

}

%bttn-stroke-mid-blue-sm {
  @include bttn-stroke($theme: 'mid-blue', $size: 'sm');
}

%bttn-stroke-bluey-grey {
  @include bttn-stroke($theme: 'bluey-grey');
}


// //////////////////////////////////////////////////////////
// // Stroke Buttons (standard) ///////////////////////
// ////////////////////////////////////////////////////////
// .#{$bttn-namespace}-stroke {
//   @include bttn-stroke();
// }

// //////////////////////////////////////////////////////////
// // Stroke Buttons (large) /////////////////////////
// ////////////////////////////////////////////////////////
// .#{$bttn-namespace}-stroke-lg {
//   @include bttn-stroke($size: lg, $font-size: lg);
// }

// //////////////////////////////////////////////////////////
// // 2.2 Stroke Buttons (xlarge) /////////////////////////
// ////////////////////////////////////////////////////////
// .#{$bttn-namespace}-stroke-xl {
//   @include bttn-stroke($size: xl, $font-size: xl);
// }
// //////////////////////////////////////////////////////////
// // 2.0 ghost Buttons (standard) ///////////////////////
// ////////////////////////////////////////////////////////
%bttn-ghost {
  @include bttn-ghost();
}

.#{$bttn-namespace}-ghost {
  @extend %bttn-ghost;
}



// //////////////////////////////////////////////////////////
// // 2.1 ghost Buttons (small) //////////////////////////
// ////////////////////////////////////////////////////////
// .#{$bttn-namespace}-ghost-sm {
//   @include bttn-ghost($size: sm);
// }

%bttn-ghost-sm {
  @include bttn-ghost($size:'sm');
}

// //////////////////////////////////////////////////////////
// // 2.2 ghost Buttons (xlarge) /////////////////////////
// ////////////////////////////////////////////////////////
// .#{$bttn-namespace}-ghost-xl {
//   @include bttn-ghost($size: xl);
// }







