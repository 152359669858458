.site-header {

  &.site-header-solid {
    $self: &;
    background-color: $color__pure_white;
    .button {
      border-color: $color__pure_white;
    }



    .site-nav {
      &-branding-link-logo.logo-white {
        display: none;
      }
      &-branding-link-logo.logo-color {
        display: inline-block;
      }
      &-toolbar-button {
        .simmer-button-svg {
          .box {
            fill: $color__pure_white;
            stroke: $color__dark_blue;
          }
          .text {
            fill: $color__dark_blue;
            stroke: none;
          }
        }
        &:hover {
          .simmer-button-svg {
            .box {
              fill: $color__dark_blue;
              stroke: $color__dark_blue;
            }
            .text {
              fill: $color__pure_white;
              stroke: none;
            }
          }
          color: $color__pure_white;
          background-color: $color__dark_blue;
        }
      }

      &-menu-item-link {
        color: rgba($color__dark_blue, 1);
        &::after {
          background-color: rgba($color__dark_blue, 0);
        }
        &:hover,
        &.active,
        &:active {
          &::after {
            background-color: rgba($color__dark_blue, 1);
          }
        }
      }
    }


  }
}
