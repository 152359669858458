.site-header {
  $self: &;
  display: none;
  width: 100%;
  // height: $site__header_height_desktop;
  height: _vw($site__header_height_desktop, 1129px);
  max-height: $site__header_height_desktop;
  flex: 1 1 100%;
  margin: 0 auto;
  padding: 0;

  &-inner {
    width: 100%;
    // padding-top: 22px;
    // padding-bottom: 32px;
    padding-top: percentage(22px / (1089px + ($gutter__mobile * 2)));
    padding-bottom: percentage(32px / (1089px + ($gutter__mobile * 2)));
    padding-left: $gutter__mobile;
    padding-right: $gutter__mobile;
  }

  @include mq(tablet_md) {
    display: flex;

    &-actions {
      padding-right: $gutter__mobile;
    }
  }

  @include mq(desktop_md) {
    &-inner {
      padding-top: 22px;
      padding-bottom: 32px;
    }
  }

}
