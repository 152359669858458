// ======================================
// SVGs
// Defaults to background-image with black fill
// Can optionally pass in a fill color and background to override
// defaults. Does not support shorthand background property.
//
// Use the extends placeholders where possible to maximize
// caching and minimize styelsheet size.
// ======================================

// sass-lint:disable string-no-newline

@mixin svg-icon($id: 'svg_placeholder', $fill_color: #000000, $property: 'background-image' ) {

  $svg: null;

  // https://rastasheep.github.io/ionicons-sprite/
  @if $id == 'ion-ios-arrow-forward' {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
      <style>.svgFill{fill: #{$fill_color};}</style>
      <path class="svgFill" d="M160 128.4L192.3 96 352 256 192.3 416 160 383.6 287.3 256z"/>
    </svg>';
  }

  @else if $id == 'ionicons-md-open' {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <style>.svgFill{fill: #{$fill_color};}</style>
    <path class="svgFill" d="M405.34 405.332H106.66V106.668H240V64H106.66C83.191 64 64 83.197 64 106.668v298.664C64 428.803 83.191 448 106.66 448h298.68c23.469 0 42.66-19.197 42.66-42.668V272h-42.66v133.332zM288 64v42.668h87.474L159.999 322.133l29.866 29.866 215.476-215.47V224H448V64H288z"/>
    </svg>';
  }

  @else if $id == 'circle-solid' {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
    <style>.svgFill{fill: #{$fill_color};}</style>
      <circle class="svgFill" cx="50" cy="50" r="50"/>
    </svg>';
  }

  // https://github.com/rastasheep/ionicons-sprite/blob/master/svg/document.svg
  // ion-document
  @else if $id == 'ionicons-document' {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <style>.svgFill{fill: #{$fill_color};}</style>
    <path class="svgFill" d="M399.3 168.9c-.7-2.9-2-5-3.5-6.8l-83.7-91.7c-1.9-2.1-4.1-3.1-6.6-4.4-2.9-1.5-6.1-1.6-9.4-1.6H136.2c-12.4 0-23.7 9.6-23.7 22.9v335.2c0 13.4 11.3 25.9 23.7 25.9h243.1c12.4 0 21.2-12.5 21.2-25.9V178.4c0-3.6-.4-6.2-1.2-9.5zM305.5 111l58 63.5h-58V111zm-161 305.5v-320h129v81.7c0 14.8 13.4 28.3 28.1 28.3h66.9v210h-224z"/>
    </svg>';
  }

  // https://github.com/rastasheep/ionicons-sprite/blob/master/svg/checkmark.svg
  // ion-document
  @else if $id == 'ionicons-checkmark' {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <style>.svgFill{fill: #{$fill_color};}</style>
    <path class="svgFill" d="M461.6 109.6l-54.9-43.3c-1.7-1.4-3.8-2.4-6.2-2.4-2.4 0-4.6 1-6.3 2.5L194.5 323s-78.5-75.5-80.7-77.7c-2.2-2.2-5.1-5.9-9.5-5.9s-6.4 3.1-8.7 5.4c-1.7 1.8-29.7 31.2-43.5 45.8-.8.9-1.3 1.4-2 2.1-1.2 1.7-2 3.6-2 5.7 0 2.2.8 4 2 5.7l2.8 2.6s139.3 133.8 141.6 136.1c2.3 2.3 5.1 5.2 9.2 5.2 4 0 7.3-4.3 9.2-6.2l249.1-320c1.2-1.7 2-3.6 2-5.8 0-2.5-1-4.6-2.4-6.4z"/>
    </svg>';
  }

  //  default blank circle
  @else {
    $svg: '<svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
    <style>.svgFill{fill: #{$fill_color};}</style>
    <path class="svgFill" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z"/>
    </svg>';
  }

  #{$property}: _svg-url($svg);

}
// sass-lint:enable string-no-newline

