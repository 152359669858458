/* Text meant only for screen readers. */
.sr,
.sr-only,
.sr-text,
.screen-reader-text,
.screen-reader-only {
  @include sr-only();
}

/* Do not show the outline on the skip link target. */
#content[tabindex = '-1']:focus,
[tabindex = '-1']:focus {
  outline: 0;
}
