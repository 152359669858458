.fullphoto {
  $self: &;
  width: 100%;
  height: auto;
  display: block;

  img {
    width: 100%;
    height: auto;
    display: block;
  }
  &-services {

  }
}
