.form {
  $self: &;
  font-size: rem(16px);

  &.form-contact {
    max-width: $site__text_content__max_width;
    margin: 0 auto;
    text-align: left;
  }

  &-control {
    margin-bottom: rem(30px);

    &-required {
      .form-label::after {
        content: '*';
        color: rgba(#f00, 1);
      }
    }


    .form-label,
    label {
      display: block;
      color: $color__off_black;
      font-weight: $font__weight_semibold;
      font-size: rem(18px);
      margin-bottom: 10px;
    }

    input {
      text-transform: none;
    }

    input[type=text],
    input[type=email],
    input[type=tel],
    textarea {
      font-size: rem(16px);
      width: 100%;
      background-color: $color__pure_white;
      border-radius: 0;
      border: 1px solid $color__pale_grey;
      outline: none;
      padding: 10px;
      &::placeholder {
        color: rgba($color__slate_grey, 0.5);
      }
    }

    textarea {
      min-height: 160px;
    }

    &-errors {
      @include typo-body();

      .form-label {
        color: $color__dark_blue;
      }
      input[type=text],
      input[type=email],
      input[type=tel]
      textarea {
        border-color: $color__pale_grey;
      }
    }

  }

  // content-body-controls
  // example next/prev buttons
  &-control-actions {
    text-align: left;

    a, button {
      margin-right: 9px;
      margin-bottom: 9px;
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }
    }
  }

  &-button-submit {
    display: inline-flex;
    padding: 15px 30px;
    font-size: rem(13px);
    font-weight: $font__weight_bold;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 3.25px;
    text-align: center;
    text-decoration: none;
    color: $color__pure_white;
    border: 1px solid $color__blue_grey;
    background-color: $color__blue_grey;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    &:hover {
      color: $color__pure_white;
      background-color: $color__dark_blue;
    }
  }

  @include mq(tablet_md) {

  }

  @include mq(desktop_sm) {


  }

}
