/*============================================================================
  #Mobile Nav
  - List of items inside the mobile drawer
==============================================================================*/
.mobile-nav__site-header-hamburger {
  height: 100%;
  width: 56px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  .hamburger {
    display: block;
    width: 55px;
    // height: 30px;
    background-color: transparent;
    padding: 0;
    outline: 0;
    border: none;
    fill: $color__pure_white;

    &-icon {
      fill: $color__pure_white;
      width: 32px;
      height: 36px;

      &-open {
        opacity: 1;
      }

      &-close {
        opacity: 0;
      }

      @at-root .js-drawer-open & {
        &-open {
          opacity: 0;
        }

        &-close {
          opacity: 1;
        }
      }

    }

    &-label {
      text-align: center;
      display: block;
      color: $color__pure_white;
      text-transform: uppercase;
      font-size: rem(10px);
      font-weight: $font__weight_regular;
      transform: translateY(-4px);
      &-open {
        display: block;
      }

      &-close {
        display: none;
      }
      @at-root .js-drawer-open & {
        &-open {
          display: none;
        }

        &-close {
          display: block;
        }
      }

    }

    &:focus {
      outline: $button__focus-outline;
    }
  }
}

.mobile-nav__site-header-logo {
  width: 100%;
  height: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  text-align: center;

  a {
    display: inline-flex;
    flex-flow: column nowrap;
    height: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 10px 10px;
    text-decoration: none;
    transform: translate(-10%, 0); // offset because of hamburger
  }
  &-img {
    width: 100%;
    height: 100%;
    &.white {
      display: block;
    }
    &.color {
      display: none;
    }
  }
}

.site-header-solid + .layout__header-inner-mobile {
  background: $color__pure_white;
  .mobile-nav__site-header-logo-img {
    &.white {
      display: none;
    }
    &.color {
      display: block;
    }
  }
  .hamburger {
    fill: $color__dark_blue;

    &-icon {
      fill: $color__dark_blue;
    }
    &-label {
      color: $color__dark_blue;
    }
  }
}



.mobile-account {
  height: 100%;
  width: 62px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: transparent;

  &-bttn {
    display: block;
    width: 62px;
    // height: 30px;
    background-color: transparent;
    padding: 0;
    outline: 0;
    border: none;
    text-decoration: none;
    fill: $color__dark_blue;
    cursor: pointer;
    font-weight: 700;
    letter-spacing: 0.02em;
    overflow-wrap: break-word;
    text-align: center;
    text-transform: uppercase;
    word-break: break-word;
    position: relative;

    &-icon {
      fill: $color__dark_blue;
      width: 35px;
      height: 35px;
    }

    &-label {
      display: block;
      // @extend %visuallyhidden;
      color: $color__dark_blue;
      text-transform: uppercase;
      font-size: rem(11px);
      font-weight: $font__weight_regular;
      transform: translateY(-4px);
    }
  }
}
