.banner-partners {
  $self: &;
  $self__max_width: 1132px;
  width: 100%;
  background-color: rgba($color__pure_white, 1);
  text-align: center;
  padding: $gutter__mobile;

  &-inner {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $color__off_white;
    padding: _vw(114px, $site__canvas_width) 0;
  }

  &-headline {
    max-width: 660px;
    font-size: rem(26px);
    font-weight: $font__weight_regular;
    color: $color__off_black;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    line-height: 2.31;
    letter-spacing: 7px;
    text-align: center;

    + p {
      margin-top: rem(15px);
    }
  }



  p {
    max-width: 618px;
    font-size: rem(18px);
    color: $color__off_black;
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    margin-bottom: rem(24px);

  }


  // ==========================================================================
  // MEDIA QUERIES
  // ==========================================================================
  @include mq(desktop_sm) {
    // padding: _vw(140px, $site__canvas_width) $gutter__desktop;

    &-inner {

    }
    &-header {
      width: 100% - percentage(615px / $self__max_width);
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-content {
      width: percentage(615px / $self__max_width);
      // display: flex;
      // flex-direction: column;
      // justify-content: center;
    }
  }
}
