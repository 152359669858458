
// JS helpers added to body when open
.js-drawer-open {
  overflow: hidden;
  height: 100%;

  @include mq(tablet_lg) {
    overflow: visible;
    height: auto;
  }

}

.drawer {
  @include promote-layer;
  @include material-animation-fast-out-slow-in(250ms);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  transform: translateX(-$nav_drawer__width);
  transition-property: transform;
  z-index: _z('drawer');

  .js-drawer-open-left & {
    transform: translateX(0);
    @include mq(tablet_lg) {
      transform: translateX(-$nav_drawer__width);
    }
  }

  &--left {
    background-color: #2a2a2a;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    transform: translateX(-100%);
  }

  &--left &__menu {
    width: $nav_drawer__width;
    height: 100%;
    transition: transform 0.15s linear;
    transition-delay: 0.2s;
    box-shadow: inset -2px 0 8px 0 rgba(0, 0, 0, 0.5);
    .js-drawer-open-left & {
      display: block;
      transform: translateX(0);
      @include mq(desktop_sm) {
        transform: none;
      }
    }

  }

}

.page-container,
.site-footer {
  @include promote-layer;
  @include material-animation-fast-out-slow-in(250ms);
}

.js-drawer-open-left {
  .page-container,
  .site-footer {
    transform: translateX($nav_drawer__width);
    // back to desktop nav if greater than mobile header showing
    @include mq(tablet_lg) {
      transform: none;
    }
  }
}
