.tandem-about {
  $self: &;
  width: 100%;
  background-color: rgba($color__pure_white, 1);

  padding: 0;
  &-inner {
    display: flex;
    flex-direction: column;
    padding: _vw(150px, $site__canvas_width) $gutter__mobile $gutter__mobile;
    max-width: $site__canvas_width;
    margin: 0 auto;
    flex-direction: column-reverse;
  }

  &-text {
    width: 100%;
    padding-top: $gutter__mobile;
    padding-bottom: $gutter__mobile * 2;
    padding-left: 0;
    padding-right: $gutter__mobile;
  }

  &-text-title {
    max-width: 560px;
    font-size: rem(22px);
    font-weight: $font__weight_regular;
    color: $color__off_black;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 5.08px;
    margin-bottom: rem(24px);
  }

  &-text-copy {
    max-width: 560px;
    font-size: rem(18px);
    color: $color__off_black;
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    margin-bottom: rem(24px);
    line-height: 1.78;
  }

  &-visuals {
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  @include mq(tablet_lg) {
    &-visuals,
    &-text {
      width: 50%;
    }

    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      padding-left: _vw(71px, $site__canvas_width);
      padding-right: _vw(71px, $site__canvas_width);
    }

    &-inner {
      padding: _vw(150px, $site__canvas_width) $gutter__mobile;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }
}
