// sass-lint:disable no-css-comments, space-before-colon, no-trailing-zero
$mq_show_breakpoints: null;
// $debug_breakpoints_position: bottom; // top or bottom
// $debug_layout: false;
// $debug_layers: false;
$debug_baseline_grid: false;
$debug_show_grid_columns: false;
// $baseline_grid_line_height: 24px;

/*============================================================================
  Table of Contents

    1. Content Sizing
    2. Directory Paths & Z-Indexes
    3. Typography
    4. Colors
    5. Module & Component Specific
==============================================================================*/

/*============================================================================
  1. Structure Content Sizing
==============================================================================*/
// PSD/Sketch/Design Canvas Width
// Used as context for calculating top/bottom margin percentages
// width of design file canvas
$site__canvas_width: 1613px;
$site__content_area_max_width: 1130px;
$site__text_content__max_width: 830px;

// Gutters
$gutter__mobile: 20px;
$gutter__tablet: 30px;
$gutter__desktop: 40px;


// Width of design content width (left/right flush)
$site__canvas_max_width: $site__canvas_width;
$site__max_width_content: $site__canvas_max_width - ($gutter__desktop * 2);

// Add 2x site gutter when calculating elements that are not full bleed
$site__max_width_including_gutters: $site__canvas_max_width - ($gutter__desktop * 2);
$site__sub_nav_height: 50px;
$site__header_height_desktop: 206px;
$site__header_height_mobile: 61px;
$site__header_shadow: 0 3px 0 rgba(242, 243, 245, 1);
$site__main_width: 100%;
$site__sidebar_width: 25%;

//-- GRID
$gutter__mobile_percent: percentage($gutter__mobile / $site__canvas_width);
$gutter__mobile_half: ($gutter__mobile / 2);
$gutter__mobile_split_percent: percentage($gutter__mobile_half / $site__canvas_width);

/*============================================================================
  Directory Paths
==============================================================================*/
//---- Images
$directory__images: '../img/';
// $svg_directory: 'assets/img/svg/';
// $svg_icon_directory: 'assets/img/svg/icons/';
// $spritesmith_ImgName: 'sprite.png';
// $spritesmith_retinaImgName: 'sprite@2x.png';

/*============================================================================
  Z-Indexes
==============================================================================*/
$z_indexes: (
  modal: 100,
  modal-backdrop: 90,
  mobile-header-hamburger: 12,
  drawer: 20,
  drawer-backdrop: 9,
  mobile-header-logo: 8,
  site-header: 7,
  mobile-site-header: 6,
  site-nav: 5,
  site-nav-panel: 4,
  desktop-nav: 3,
  main-toolbar: 3,
  thumbnail-hover: 2,
  thumbnail-hover-img: -1,
  site-content: 1
);

/*============================================================================
  Transitions & Animations
==============================================================================*/
// From Material Design Lite
// https://github.com/google/material-design-lite
// src/_variables.scss#L497
$animation__curve_fast_out_slow_in: cubic-bezier(0.4, 0, 0.2, 1) !default;
$animation__curve_linear_out_slow_in: cubic-bezier(0, 0, 0.2, 1) !default;
$animation__curve_fast_out_linear_in: cubic-bezier(0.4, 0, 1, 1) !default;
$animation__curve_default: $animation__curve_fast_out_slow_in !default;

$transition__default_duration: 0.4s;
$transition__default_function: cubic-bezier(0.46, 0.01, 0.32, 1);
$transition__default: $transition__default_duration $transition__default_function;

/*============================================================================
  3. Typography
==============================================================================*/

//---- Font Families
// Fallbacks found using: https://meowni.ca/font-style-matcher/
$sans: 'Montserrat', -apple-system, sans-serif;
$serif: 'Abril Fatface', Georgia, -apple-system, serif;
$monospace: Monaco, Consolas, 'Andale Mono', 'DejaVu Sans Mono', -apple-system, monospace;
$monospace_alt: 'Courier 10 Pitch', Courier, -apple-system, monospace;
// TODO: Add font awesome
// FontAwesome Free Solid (900)

//---- Font Weights
$font__weight_regular: 400;
$font__weight_book: 400;
$font__weight_semibold: 600;
$font__weight_bold: 700;

$font__base_family: $sans !default;
$font__base_size: 16px; // Always 16px

$font__base_weight: $font__weight_regular !default;
$font__base_line_height: 1.25 !default;

// Body Copy
$font__body_family: $sans;
$font__body_weight: $font__weight_book;
$font__body_size_min: rem(16px);
$font__body_size: rem(16px);
$font__body_line_height: 1.5;
$font__body_style: normal;
$font__body_letter_spacing: normal;

$font__body_lrg_family: $sans;
$font__body_lrg_weight: $font__weight_book;
$font__body_lrg_size_min: rem(18px);
$font__body_lrg_size: rem(18px);
$font__body_lrg_line_height: 1.33;
$font__body_lrg_style: normal;
$font__body_lrg_letter_spacing: normal;

// HEADINGS
$font__hero_headline_size_min: rem(44px);
$font__hero_headline_size: rem(44px);
$font__hero_headline_family: $sans;
$font__hero_headline_weight: $font__weight_semibold;
$font__hero_headline_line_height: 1.52;
$font__hero_headline_style: normal;
$font__hero_headline_letter_spacing: 11px;


// <h1>
$font__headline_size_min: rem(28px);
$font__headline_size: rem(28px);
$font__headline_family: $sans;
$font__headline_weight: $font__weight_semibold;
$font__headline_line_height: 1.43;
$font__headline_style: normal;
$font__headline_letter_spacing: normal;

$font__subhead_size_min: rem(24px);
$font__subhead_size: rem(24px);
$font__subhead_family: $sans;
$font__subhead_weight: $font__weight_semibold;
$font__subhead_line_height: 1.42;
$font__subhead_style: normal;
$font__subhead_letter_spacing: normal;

$font__title_size_min: rem(18px);
$font__title_size: rem(18px);
$font__title_family: $sans;
$font__title_weight: $font__weight_semibold;
$font__title_line_height: 1.33;
$font__title_style: normal;
$font__title_letter_spacing: normal;


// Title <h3>, .title, .h3
$font__subtitle_family: $sans;
$font__subtitle_weight: $font__weight_semibold;
$font__subtitle_size_min: rem(24px);
$font__subtitle_size: rem(24px);
$font__subtitle_line_height: 1.42;
$font__subtitle_style: normal;
$font__subtitle_letter_spacing: normal;


// <h4>
$font__h4_size: rem(13px);
$font__h4_size_min: rem(13px);
$font__h4_line_height: 1.4;
$font__h4_weight: $font__weight_semibold;
$font__h4_family: $sans;
// <h5>
$font__h5_size: 12px;
$font__h5_size_min: 12px;
$font__h5_line_height: 38px;
$font__h5_weight: $font__weight_semibold;
$font__h5_family: $sans;
// <h6>
$font__h6_size: 12px;
$font__h6_size_min: 12px;
$font__h6_line_height: 38px;
$font__h6_weight: $font__weight_semibold;
$font__h6_family: $sans;

// <dt>
$font__dt_weight: $font__weight_bold !default;
// <CODE>
$font__code: $monospace;
$font__code_size: 14px;

// <PRE>
$font__pre: $monospace_alt;
$font__pre_size: 14px;
$font__pre_line_height: 1.6;

/*============================================================================
  4. Colors
// ==============================================================================*/

//---- Color Names
$color__dark_blue: #003168;
$color__pale_grey: #ccc;
$color__blue_grey: #b0b7bb;
$color__slate_grey: #626566;
$color__pure_black: #000;
$color__off_black: #333;
$color__pure_white: #fff;
$color__off_white: #fafafa;

//---- Helper colors
$color__focus_outline: rgba($color__pale_grey, 1);

// Body
//
// Settings for the `<body>` element.
$color__body_background: $color__pure_white;
$color__body_text: $color__slate_grey !default;
$color__body_text_alt: $color__slate_grey !default;

// Links
//
// Style anchor `<a>` elements.
$link__color: $color__dark_blue !default;
$link__color_hover: darken($link__color, 15%) !default;
$link__decoration: none !default;
$link__decoration_hover: underline !default;
$focus__outline_style: 1px dotted $color__focus_outline;
/* Borders */
$border__width: 1px !default;
$color__border: $color__blue_grey;

/* Horizontal Rules */
// Style anchor `<hr>` elements.
$width__hr_border: 3px !default;
$color__hr: $color__pale_grey;

/* Selections */
$color__selection: lighten($color__dark_blue, 4%);
$color__selection_background: $color__blue_grey;



/*============================================================================
  5. Module/Component Settings
==============================================================================*/
// ---- Site Nav

// -------- Top Level
$site_nav__height_desktop: $site__header_height_desktop;
$site_nav__height_mobile: $site__header_height_mobile;
$site_nav__item_padding_large: 13px;
$site_nav__item_padding_xlarge: 13px;
$site_nav__color_text: $color__pure_white;
$site_nav__color_text_opacity: 1;
$site_nav__background_color: $color__pure_white;
$site_nav__background_color_opacity: 1;


//-- Main toolbar
$toolbar__height: 46px;
// Cart toolbar
$cartbar__height: 65px;

/*
   Borders
   ========================================================================== */
$default__border: 1px solid rgba($color__border, 0.75);


/*
   <BUTTON>
========================================================================== */
$button__font_family: $sans;
$button__font_size: 14px;
$button__font_weight: $font__weight_bold;
$button__line_height: 1;
$button__letter_spacing: null;
$button__color_text: $color__dark_blue;
$button__color_text_hover: $color__pure_white;
$button__background_color: $color__pure_white;
$button__background_color_hover: $color__dark_blue;
$button__color_text_disabled: darken($color__blue_grey, 25%);
$button__padding: 14px 20px;
$button__font_size: 20px;
$button__focus_outline_width: thin;
$button__focus_outline_color: $color__blue_grey;
$button__focus_outline_style: dotted;
$button__focus_outline: $button__focus_outline_width $button__focus_outline_style $button__focus_outline_color;
$button__min-width-mobile: 120px;
$button__min-width-desktop: 180px;
// Headings
//
// Style h1 - h6 element.
$text__margin_bottom_xxs: rem($gutter__mobile * 0.1);
$text__margin_bottom_xs: rem($gutter__mobile * 0.15);
$text__margin_bottom_sm: rem($gutter__mobile * 0.25);
$text__margin_bottom_normal: rem($gutter__mobile * 0.5);

$text__margin_bottom_med: rem($gutter__mobile * 0.75);
$text__margin_bottom_lg: rem($gutter__desktop * 0.5);
$text__margin_bottom_xl: rem($gutter__desktop * 0.75);
// Paragraphs
//
// Style p element.
$paragraph__margin_bottom: rem(10px);
$paragraph__font_family: $sans;

// Horizontal Rules <hr>
$spacing__hr_mobile: $gutter__mobile - ($width__hr_border / 2);
$spacing__hr_desktop: $gutter__desktop - ($width__hr_border / 2);

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table__bg: transparent !default;
$table__border_width: $border__width !default;

$table__font_family: $sans;
$table__caption_side: top;
$table__head_bg: $color__blue_grey !default;
$table__head_color: $color__pure_white !default;
$table__head_cell_padding_vertical: $gutter__mobile_half !default;
$table__body_color: $color__blue_grey !default;
$table__body_cell_bg: $color__pure_white !default;
$table__cell_padding_vertical: $gutter__mobile - 4px !default;
$table__cell_padding_horizontal: $gutter__mobile_half !default;
$table__cell_padding_sm: 0.3rem !default;
$table__striped_order: odd !default;


// ==========================================================================
// Drawers
// ==========================================================================

// Nav Drawer (drawer--left)
$nav_drawer__width: 257px;
$nav_drawer__background_color: $color__dark_blue;

// Nav Drawer Menu  (.drawer__menu)
$nav_drawer_menu__gutter: $gutter__mobile;
$nav_drawer_menu__color_text: $color__pure_white;
$nav_drawer_menu__text_transform: none;
$nav_drawer_menu__font_weight: $font__weight_regular;
$nav_drawer_menu__font_family: $sans;
$nav_drawer_menu__font_size: rem(24px);

// Nav Drawer Menu Header Vars
$nav_drawer_menu__color_text_header: rgba(#a9acb2, 1);
$nav_drawer_menu__background_color_header: rgba(#3c3d40, 1);
$nav_drawer_menu__font_weight_header: $font__weight_regular;
$nav_drawer_menu__font_family_header: $sans;
$nav_drawer_menu__font_size_header: rem(18px);
$nav_drawer_menu__spacer_header: 15px;
// sass-lint enable no-css-comments, space-before-colon, no-trailing-zero
