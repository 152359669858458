.contact {
  $self: &;
  $self_max_width: 1129px;
  width: 100%;
  background-color: rgba($color__pure_white, 1);
  text-align: center;
  padding: $gutter__mobile;

  &-inner {
    margin: auto;
    background-color: $color__off_white;
    padding: 50px $gutter__mobile;
  }

  &-header {
    margin-bottom: rem(50px);
  }

  &-header-headline {
    font-size: rem(26px);
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: 6.5px;
    text-align: center;
    color: $color__off_black;
    text-transform: uppercase;
  }

  &-content {
    max-width: $self_max_width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
  }

  // &-content-rail,
  // &-content-actions {
  //   padding-left: $gutter__mobile;
  //   padding-right: $gutter__mobile;
  // }
  &-content-rail {
    text-align: left;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    p {
      font-size: rem(18px);
      font-weight: $font__weight_regular;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.78;
      letter-spacing: normal;
      color: $color__off_black;
      margin-bottom: rem(24px);
    }
  }

  &-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: rem(24px);
  }

  &-content-rail &-content-rail-links {
    display: none;
  }

  &-phone-row {
    margin-bottom: rem(24px);
  }

  &-social-row {
    margin-bottom: rem(24px);
    .share-icon-link {
      color: $color__dark_blue;
      fill: $color__dark_blue;
      line-height: 1;
      padding: 5px;
      text-decoration: none;
      &:first-child {
        padding-left: 0;
      }
      .share-icon {
        // margin-bottom: ;
        font-size: rem(18px);
        line-height: 1;
        width: 1em;
        height: 1em;
        text-align: center;
      }
    }
  }

  &-content-actions {
    text-align: left;

    p {
      font-size: rem(18px);
      font-weight: $font__weight_regular;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.78;
      letter-spacing: normal;
      color: $color__off_black;

      &:first-child {
        margin-bottom: rem(49px);
      }

    }
  }

  &-headline {
    font-size: rem(26px);
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: 6px;
    color: $color__off_black;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: $gutter__mobile;
  }

  &-byline {
    font-size: rem(18px);
    font-weight: $font__weight_semibold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.67;
    letter-spacing: normal;
    color: $color__off_black;
    display: block;
    margin-bottom: rem(36px);
  }

  @include mq(tablet_sm) {

  }


  @include mq(desktop_sm) {
    &-inner {
      padding: _vw(114px, $site__canvas_width) $gutter__mobile;
    }

    &-content {
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
    }

    &-content-rail,
    &-content-actions {
      width: percentage(525px / $self_max_width);
      padding-left: $gutter__mobile;
      padding-right: $gutter__mobile;
    }

    &-content-rail &-content-rail-links {
      display: flex;
    }

    &-content-actions &-action-links {
      display: none;
    }
    &-phone-row {
      margin-top: rem(32px);
      margin-bottom: rem(32px);
    }

    &-social-row {
      margin-bottom: rem(24px);
      .share-icon-link {
        color: $color__dark_blue;
        fill: $color__dark_blue;
        line-height: 1;
        padding: 5px;
        text-decoration: none;
        &:first-child {
          padding-left: 0;
        }
        .share-icon {
          font-size: rem(21px);
        }
      }
    }

  }
}
