// ==========================================================================
// Columns list (.columns-list)
// A module for converting a <ul> into a multi column layout
// example use: resources > models > index
// ==========================================================================
.service-list {
  $self: &;
  $self_max_width: 1129px;
  width: 100%;
  background-color: rgba($color__pure_white, 1);
  text-align: center;

  &-inner {
    margin: auto;
    background-color: $color__off_white;
    padding: 80px $gutter__mobile 30px $gutter__mobile;
  }


  &-header {
    text-align: center;
    padding-bottom: 50px;
    padding-left: $gutter__mobile;
    padding-right: $gutter__mobile;
  }

  &-headline {
    text-transform: uppercase;
    font-size: rem(15px);
    font-weight: $font__weight_semibold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 3.75px;
    text-align: center;
    color: $color__off_black;
  }

  &-listing-wrap {
    max-width: $self_max_width;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &-listing {
    width: 100%;
    list-style: none;
    text-align: left;
    padding: 0;
    margin: 0;

    li {
      line-height: 1.78;
      margin-bottom: rem(32px);
      position: relative;
      padding: 0 0 0 36px;
      break-inside: avoid-column;
      overflow: auto;

      &::before {
        @include bg_image('summit-bullet-point.svg');
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 2px;
        width: 24px;
        height: 24px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      strong {
        display: block;
      }
    }
  }

  @include mq(tablet_md) {
    &-inner {
      padding: _vw(114px, $site__canvas_width) 0;
    }

    &-header {
      padding-bottom: _vw(80px, $site__canvas_width);
    }

    &-listing-wrap {
      flex-direction: row;
    }

    &-listing {
      width: 50%;
    }

    &-listing:first-child {
      padding-right: rem(29.5px);
      padding-left: rem(29.5px);

      + ul {
        padding-right: rem(29.5px);
        padding-left: rem(29.5px);
      }
    }
  }
}
