/* stylelint-disable no-descending-specificity */
//
// Hide things while fonts are loading to prevent FOUC
//
.wf-loading {
  body {
    opacity: 0;
    @include material-animation-default();
  }
}
//
// Transition things back in when they are loaded
//
body {
  opacity: 1;
  @include material-animation-default();
}

/* stylelint-enable no-descending-specificity */
