.superhero {
  $self: &;
  &.superhero-contact {
    @include background_image_retina('superhero-contact.jpg', 'superhero-contact@2x.jpg');
    @include mq(tablet_md) {
      .rwd-br {
        display: inline;
      }
    }
  }
}
