.button {
  display: inline-flex;
  padding: 7px 10px;
  font-size: rem(10px);
  font-weight: $font__weight_bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.82;
  letter-spacing: 0.9px;
  text-align: center;
  text-decoration: none;
  color: $color__pale_grey;
  border: 1px solid $color__pale_grey;
  background-color: transparent;
  &:hover {
    color: $color__dark_blue;
    background-color: $color__pale_grey;
  }
}
