.mosaic {
  $self: &;
  $self_canvas_width: 1631px;

  &-top-row {
    display: flex;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border: 1px solid $color__pure-white;
    }

    &-left {
      width: percentage(1 / 3);
      height: _vw(339px, $self_canvas_width);  //image height,width
      position: relative;
      img {
        border-left: 0;
      }
    }

    &-middle {
      width: percentage(1 / 3);
      height: _vw(339px, $self_canvas_width);  //image height,width
    }

    &-right {
      width: percentage(1 / 3);
      height: _vw(339px, $self_canvas_width);  //image height,width
      img {
        border-right: 0;
      }
    }
  }

  &-bottom-row {
    width: 100%;
    border-top: 1px solid $color__pure-white;
    position: relative;
    height: _vw(572px, $self_canvas_width); //image height,width

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

  }

  @include mq(tablet_lg) {
    &-top-row {
      img {
        border: 2px solid $color__pure-white;
      }
      &-left img {
        border-left: 0;
      }
      &-right img {
        border-right: 0;
      }
    }
    &-bottom-row {
      border-top: 2px solid $color__pure-white;
    }
  }
}
