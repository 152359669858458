// ==========================================================================
// _svg-url
// Function (and helpers) to encode SVG string as data uri
// backwards compatable to at least IE11
//
// @source Source: http://codepen.io/jakob-e/pen/doMoML
// ** custom modifications have been made to the original source **
// ** alter at your own peril (ノಠ益ಠ)ノ彡┻━┻ **
// ==========================================================================



@function _svg-url($svg) {
  //
  //  Chunk up string in order to avoid "stack level too deep" error
  // =======================================================================
  $encoded: '';
  $slice: 2000;
  $index: 0;
  $loops: ceil(str-length($svg) / $slice);
  @for $i from 1 through $loops {
    $chunk: str-slice($svg, $index, $index + $slice - 1);
    //  Encode (may need a few extra replacements)
    $chunk: _str-replace($chunk, '"', "'");
    $chunk: _str-replace($chunk, '<', '%3C');
    $chunk: _str-replace($chunk, '>', '%3E');
    $chunk: _str-replace($chunk, '&', '%26');
    $chunk: _str-replace($chunk, '#', '%23');
    $chunk: _str-replace($chunk, '{', '%7b');
    $chunk: _str-replace($chunk, '}', '%7d');
    //
    //    The maybe list
    // =======================================================================
    //    Keep size and compile time down
    //    ... only add on documented fail
    //
    //  $chunk: str-replace($chunk, '|', '%7C');
    //  $chunk: str-replace($chunk, '[', '%5B');
    //  $chunk: str-replace($chunk, ']', '%5D');
    //  $chunk: str-replace($chunk, '^', '%5E');
    //  $chunk: str-replace($chunk, '`', '%60');
    //  $chunk: str-replace($chunk, ';', '%3B');
    //  $chunk: str-replace($chunk, '?', '%3F');
    //  $chunk: str-replace($chunk, ':', '%3A');
    //  $chunk: str-replace($chunk, '@', '%40');
    //  $chunk: str-replace($chunk, '=', '%3D');
    $encoded: #{$encoded}#{$chunk};
    $index: $index + $slice;
  }
  @return url('data:image/svg+xml;charset=UTF-8,#{$encoded}');
}

// Background svg mixin
// @mixin background-svg($svg) {
//   background-image: svg-url($svg);
// }
//

// Helper function to replace characters in a string
// ==========================================================================
@function _str-replace($string,
$search,
$replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string,
    1,
    $index - 1)+$replace+_str-replace( str-slice($string, $index + str-length($search)),
    $search,
    $replace);
  }
  @return $string;
}
