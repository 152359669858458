.superhero {
  $self: &;
  &.superhero-partners {
    @include background_image_retina('superhero-partners.jpg', 'superhero-partners@2x.jpg');
    @include mq(desktop_md) {
      .rwd-br {
        display: inline;
      }
    }
  }
}
