.site-footer {
  $self: &;
  display: flex;
  width: 100%;
  flex: 1 1 100%;
  margin: 0 auto;
  padding: 0;

  &-inner {
    width: 100%;
    padding: 24px $gutter__mobile;
  }

  &-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    width: 100%;
    max-width: 1089px + ($gutter__mobile * 2);
  }

  &-branding {
    max-width: 178px;
    margin-bottom: 14px;

    &-link {
      display: inline-block;
    }
    &-link-logo {
      width: 100%;
      height: auto;
      &.logo-white {
        display: inline-block;
      }

      &.logo-color {
        display: none;
      }
    }
  }

  &-menu-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  &-menu-top,
  &-menu-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &-menu-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1px 7px;
  }

  &-menu-row.contact-row {
    margin-bottom: 7px;
    a {
      font-size: 13px;
    }
    a + span {
      display: inline-block;
      width: _em(13px);
      text-align: center;
      color: $color__slate_grey;
      pointer-events: none;
    }
    a:first-child::before {
      display: none;
    }
  }

  &-menu-row.legal-row,
  &-menu-row.copyright-row {
    font-size: rem(13px);
    font-weight: $font__weight_regular;
    line-height: 1.85;
    letter-spacing: 0.54px;
    color: $color__slate_grey;
    a {
      color: $color__slate_grey;
      text-decoration: none;
      &:hover {
        color: $color__dark_blue;
        text-decoration: underline;
      }
    }
  }

  &-menu-row.legal-row {
    a {
      margin: 0 15px 0 15px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-menu-row.copyright-row {
    flex-direction: column;
    .pipe {
      display: none;
    }
  }
  .share-icon-link {
    color: $color__dark_blue;
    fill: $color__dark_blue;
    line-height: 1;
    padding: 7px;
    .share-icon {
      font-size: rem(21px);
      line-height: 1;
      width: 1em;
      height: 1em;
      text-align: center;
    }
  }









  @include mq(mobile_lg) {
    &-menu-row.contact-row {
      a + span {
        display: inline-block;
        width: _em(32px);
      }
    }

    &-menu-row.copyright-row {
      flex-direction: row;
      .pipe {
        display: inline;
      }
    }
  }


  @include mq(tablet_md) {
    min-height: 255px;

    &-inner {
      // padding: 48px $gutter__desktop;
    }

    &-branding {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    &-menu-area {
      height: 100%;
    }

    &-content {
      flex-direction: row;
    }
    &-branding {
      margin-bottom: 0;
    }
    &-menu-row {
      justify-content: flex-end;
    }
  }


  @include mq(desktop_sm) {
    &-inner {
      padding-top: 48px;
      padding-bottom: 48px;
    }
  }
}
