.tandem {
  $self: &;
  width: 100%;
  background-color: rgba($color__pure_white, 1);
  padding: 10px 20px;
  // @include debug-outline;

  &-inner {
    display: flex;
    flex-direction: column;
  }

  &--off-white &-inner {
    background-color: rgba($color__off_white, 1);
  }

  &-text {
    width: 100%;
    padding-top: $gutter__mobile;
    padding-bottom: $gutter__mobile * 2;
    padding-left: $gutter__mobile;
    padding-right: $gutter__mobile;
  }

  &-text-title {
    // max-width: 560px;
    font-size: rem(22px);
    font-weight: $font__weight_regular;
    color: $color__off_black;
    text-transform: uppercase;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.36;
    letter-spacing: 5.08px;
    margin-bottom: rem(24px);
  }

  &-text-copy {
    // max-width: 560px;
    font-size: rem(18px);
    color: $color__off_black;
    font-weight: $font__weight_regular;
    font-style: normal;
    font-stretch: normal;
    line-height: 2;
    letter-spacing: normal;
    margin-bottom: rem(24px);
  }

  &-visuals {
    position: relative;
    height: 0;
    padding-bottom: _vw(640, 1572);
    margin-bottom: $gutter__mobile;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      // opacity: 0;
    }
    // &.home-partners {
    //   @include background_image_retina('tandem-partners-long.jpg', 'tandem-partners-long@2x.jpg');
    //   background-repeat: no-repeat;
    //   background-size: cover;
    //   background-position: center;
    // }
  }

  &--text-right &-text {
    @include mq(desktop_sm) {
      padding-left: $gutter__desktop * 2;
    }
  }




  // ==========================================================================
  // MEDIA QUERIES
  // ==========================================================================
  @include mq(desktop_sm) {


    &-inner {
      flex-direction: row;
    }

    &-text {
      width: percentage(866px / 1572px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: _vw(71px, $site__canvas_width);
      padding-right: _vw(71px, $site__canvas_width);
    }

    &-text-copy,
    &-text-title {
      max-width: 560px;
    }

    &-visuals {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100% - percentage(866px / 1572px);
      padding-bottom: 50%;
      margin-bottom: 0;

      img {
        object-position: left center;
      }
    }

    &--text-left &-inner {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    &--text-left &-text {
      align-items: flex-end;
    }

  }


  @include mq(desktop_xl) {
    &-inner {
      max-height: 640px;
      min-height: 640px;
    }
    &-text {
      // width: percentage(866px / 1572px);
    }
    &-visuals {
      // width: 100% - percentage(866px / 1572px);
      position: relative;
      height: auto;
      padding-bottom: 0;
    }
  }


}
