.partner {
  $self: &;
  $self__max_width: 1126px;
  padding: 0 $gutter__mobile;
  margin-bottom: 50px;

  &:first-child {
    margin-top: 50px;
  }
  &:last-child {
    margin-bottom: 100px;
  }

  &-inner {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    max-width: $self__max_width;
    margin: 0 auto;
  }

  &-logo {
    // flex-basis: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: rem(24px);
  }

  &-logo-img-wrap {
    // wrap just to keep image from distorting
    img {
      min-height: 1px;
      max-width: 100%;
      max-height: none;
      height: auto;

    }
  }

  &-info {
    width: 100%;
  }

  &-name {
    font-size: rem(24px);
    font-weight: $font__weight_semibold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: $color__off_black;
    margin-bottom: rem(24px);
  }

  &-desc {
    // width: 774px;
    p {
      font-size: rem(18px);
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 2;
      letter-spacing: normal;
      color: $color__off_black;

      a {
        color: $color__dark_blue;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  @include mq(tablet_sm) {
    margin-bottom: _vw(100px, $site__canvas_width);

    &:first-child {
      margin-top: _vw(100px, $site__canvas_width);
    }
    &:last-child {
      margin-bottom: _vw(150px, $site__canvas_width);
    }


    &-inner {
      flex-direction: row;
    }

    &-logo {
      flex-basis: percentage(($self__max_width - 774px) / $self__max_width);
      padding-right: percentage((95px - $gutter__mobile) / $self__max_width);
      padding-left: percentage($gutter__mobile / $self__max_width);
      margin-bottom: 0;
    }

    // &-logo-img-wrap {
    //   img {}
    // }

    &-info {
      flex-basis: percentage(774px / $self__max_width);
    }
  }

}
