$bttn-namespace: 'bttn' !default;
////////////////////////////////////////////////////////////
// BUTTONS BASE DEFAULTS //////////////////////////////
//////////////////////////////////////////////////////////

$bttn-font-family: $sans !default;
$bttn-font-size-base: 16px !default; //almost always 16
$bttn-font-weight: $font__weight_bold !default;
$bttn-letter-spacing: _get-font-tracking(20) !default;
$bttn-text-transform: uppercase !default;
$bttn-display: inline-block !default;

$bttn-border-width: 3px !default;
$bttn-border-style: solid !default;
$bttn-border-radius: 4px !default;
$bttn-transition-duration: 0.3s !default;
$bttn-transition-function: $animation__curve_default;
// ------------------------------------------
// SIZING (padding, font-size, line-height)
// Size and Font Size
// ------------------------------------------

// SM -- $size: sm
$bttn-padding-sm: 11px 7px 10px 8px !default;
// SM -- $font-size: sm
$bttn-font-size-sm: bttn-rem(16px) !default;
$bttn-line-height-sm: 1.5 !default;

// DEFAULT: size
$bttn-padding: 13px 14px 12px 14px !default;
// DEFAULT: font-size
$bttn-font-size: bttn-rem(16px) !default;
$bttn-line-height: 1.5 !default;

// LG -- $size: lg
$bttn-padding-lg: 14.5px 7px 13.5px 8px !default;
// LG -- $font-size: lg
$bttn-font-size-lg: bttn-rem(16px) !default;
$bttn-line-height-lg: 1.5 !default;

// XL -- $size: xl
$bttn-padding-xl: 30px 34px !default;
// XL -- $font-size: xl
$bttn-font-size-xl: bttn-rem(18px) !default;
$bttn-line-height-xl: bttn-lh(18px, 20px) !default;

// ------------------------------------------
// DEFAULT THEMES FOR EACH BUTTON TYPE
// ------------------------------------------

//  bttn-PRIMARY DEFAULT THEME
$bttn-theme-defaults: map-get($map-bttn-themes, 'default') !default;
//  bttn-STROKE DEFAULT THEME
$bttn-stroke-theme-defaults: map-get($map-bttn-stroke-themes, 'default') !default;
// bttn-ghost DEFAULT THEME
$bttn-ghost-theme-defaults: map-get($map-bttn-ghost-themes, 'default') !default;
// bttn-PILL DEFAULT THEME
$bttn-pill-theme-defaults: map-get($map-bttn-pill-themes, 'default') !default;
