.superhero {
  $self: &;
  &.superhero-about {
    @include background_image_retina('superhero-about-v3.jpg', 'superhero-about-v3@2x.jpg');
    background-position: bottom center;
    @include mq(tablet_md) {
      .rwd-br {
        display: inline;
      }
    }
  }
}
