// ==========================================================================
// Top Level page containers (mostly)
//
// Implemented flexbox sticky footer styles here per this reduced case on codepen.
// Pen: https://codepen.io/j-cam/pen/4818f2dea635a5e0fe31623bd1e06ec6/
// Forked from: https://codepen.io/chriscoyier/pen/RRbKrL
// Article: https://css-tricks.com/couple-takes-sticky-footer/
// ==========================================================================
$module: 'layout';

.#{$module} {

  // height: 100%;
  &__body {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    min-height: 100%;
    min-height: 100vh;
    /* Avoid the IE 10-11 `min-height` bug. */
    overflow-x: hidden;
  }

  //
  // TODO:
  // needs switched in dev/prod to layout__pageContainer
  //
  .page-container {
    flex: 1 0 auto;
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  }

  &__header {
    z-index: _z('site-header');
    width: 100%;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
  }

  &__header-inner-mobile {
    z-index: _z('drawer');
    width: 100%;
    height: 64px;
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 0 0 rgba(172, 172, 172, 0.5);

    .mobile-nav__site-header-logo {
      z-index: _z('mobile-header-logo');
    }

    .mobile-nav__site-header-hamburger {
      z-index: _z('mobile-header-hamburger');
    }
  }

  &__header-inner-desktop {
    z-index: _z('desktop-nav');
    display: none;
  }

  &__main {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }

  &__drawer {
    z-index: _z('drawer');
  }

  &__page-main {
    width: 100%;
    min-height: 100%;
  }

  &__footer {
    width: 100%;
    flex-shrink: 0;
    /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
  }

  //
  // Show sub-nav
  // ============================================
  @include mq(tablet_md) {
    &__header-inner-mobile {
      display: none;
    }

    &__drawer {
      // padding-top: $site__header_height_mobile;
    }
  }

  //
  //  - Hide mobile header
  //  - Show desktop nav
  // ============================================
  @include mq(desktop_sm) {

    &__header {}

    &__header-inner-desktop {
      display: block;
    }
  }
}
